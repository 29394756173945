@mixin mobile {
    @media (max-width: 425px) { @content; }
  }
  @mixin tablet {
    @media (min-width: 425px) and (max-width:1023px) { @content; }
  }
  @mixin toTablet {
    @media (max-width: 1023px) { @content; }
  }
  @mixin desktop {
    @media (min-width: 1024px) { @content; }
  }
  @mixin desktop-xl {
    @media (min-width: 1441px) { @content; }
  }
