$dark-blue-color: #545775;
$light-blue-color: #718F94;
$green-color: #90B494;
$color-danger: #b74040;
$color-black: black;
$color-white: white;


$color-dark-blue: #18163D;
$color-gold: #FFDE59;
$color-text-white: #ffffff;
$color-auro-metal-saurus: #65837B;
$color-khaki: #65837B;
$color-sand: #EDDB94;
$color-gray: #769089; 

$color-greenish-gray: #F1F1F1;