@font-face {
    font-family: 'icomoon';
    src:  url('./../assets/fonts/icomoon.eot?1876fy');
    src:  url('./../assets/fonts/icomoon.eot?1876fy#iefix') format('embedded-opentype'),
      url('./../assets/fonts/icomoon.ttf?1876fy') format('truetype'),
      url('./../assets/fonts/icomoon.woff?1876fy') format('woff'),
      url('./../assets/fonts/icomoon.svg?1876fy#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-icon-arrow:before {
    content: "\e904";
  }
  .icon-heart:before {
    content: "\e903";
  }
  .icon-icon-map:before {
    content: "\e905";
  }

  .icon-icon-filter:before {
    content: "\e900";
  }
  .icon-icon-tractor:before {
    content: "\e906";
  }
  
  .icon-icon-checklist:before {
    content: "\e92c";
  }
  .icon-icon-doc:before {
    content: "\e92d";
  }
  .icon-icon-care .path1:before {
    content: "\e92e";
    color: #36504b;
  }
  .icon-icon-care .path2:before {
    content: "\e92f";
    margin-left: -1.181640625em;
    color: #36504b
  }
  .icon-icon-care .path3:before {
    content: "\e930";
    margin-left: -1.181640625em;
    color: #36504b
  }
  .icon-icon-care .path4:before {
    content: "\e931";
    margin-left: -1.181640625em;
    color: #36504b
  }
  .icon-icon-care .path5:before {
    content: "\e932";
    margin-left: -1.181640625em;
    color: #36504b
  }
  .icon-icon-care .path6:before {
    content: "\e933";
    margin-left: -1.181640625em;
    color: #36504b
  }
  .icon-icon-care .path7:before {
    content: "\e934";
    margin-left: -1.181640625em;
    color: #36504b
  }
  .icon-icon-group:before {
    content: "\e935";
  }
  .icon-icon-clock:before {
    content: "\e936";
  }
  .icon-icon-auction .path1:before {
    content: "\e937";
    // color: rgb(35, 31, 32);
  }
  .icon-icon-auction .path2:before {
    content: "\e938";
    margin-left: -1.0341796875em;
    // color: rgb(35, 31, 32);
  }
  .icon-icon-auction .path3:before {
    content: "\e939";
    margin-left: -1.0341796875em;
    // color: rgb(35, 31, 32);
  }
  .icon-icon-auction .path4:before {
    content: "\e93a";
    margin-left: -1.0341796875em;
    color: rgb(255, 255, 255);
  }
  .icon-icon-auction .path5:before {
    content: "\e93b";
    margin-left: -1.0341796875em;
    // color: rgb(35, 31, 32);
  }
  .icon-icon-auction .path6:before {
    content: "\e93c";
    margin-left: -1.0341796875em;
    // color: rgb(35, 31, 32);
  }
  .icon-icon-auction .path7:before {
    content: "\e93d";
    margin-left: -1.0341796875em;
    // color: rgb(35, 31, 32);
  }
  .icon-icon-auction .path8:before {
    content: "\e93e";
    margin-left: -1.0341796875em;
    // color: rgb(35, 31, 32);
  }
  .icon-icon-auction .path9:before {
    content: "\e93f";
    margin-left: -1.0341796875em;
    // color: rgb(35, 31, 32);
  }
  .icon-icon-handshake:before {
    content: "\e940";
  }
  .icon-icon-hourglass:before {
    content: "\e949";
  }
  .icon-icon-alert:before {
    content: "\e94a";
  }
  .icon-icon-person:before {
    content: "\e94b";
  }
  .icon-icon-recycle:before {
    content: "\e94c";
  }
  .icon-icon-switch:before {
    content: "\e94d";
  }
  .icon-Glyph-Icon-3 .path1:before {
    content: "\e94e";
    color: rgb(255, 255, 255);
  }
  .icon-Glyph-Icon-3 .path2:before {
    content: "\e94f";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path3:before {
    content: "\e950";
    margin-left: -1.56640625em;
    color: rgb(255, 255, 255);
  }
  .icon-Glyph-Icon-3 .path4:before {
    content: "\e951";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path5:before {
    content: "\e952";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path6:before {
    content: "\e953";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path7:before {
    content: "\e954";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path8:before {
    content: "\e955";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path9:before {
    content: "\e956";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path10:before {
    content: "\e957";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path11:before {
    content: "\e958";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path12:before {
    content: "\e959";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path13:before {
    content: "\e95a";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path14:before {
    content: "\e95b";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path15:before {
    content: "\e95c";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path16:before {
    content: "\e95d";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path17:before {
    content: "\e95e";
    margin-left: -1.56640625em;
    color: rgb(255, 255, 255);
  }
  .icon-Glyph-Icon-3 .path18:before {
    content: "\e95f";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path19:before {
    content: "\e960";
    margin-left: -1.56640625em;
    color: rgb(255, 255, 255);
  }
  .icon-Glyph-Icon-3 .path20:before {
    content: "\e961";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path21:before {
    content: "\e962";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path22:before {
    content: "\e963";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path23:before {
    content: "\e964";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path24:before {
    content: "\e965";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path25:before {
    content: "\e966";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path26:before {
    content: "\e967";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path27:before {
    content: "\e968";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path28:before {
    content: "\e969";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path29:before {
    content: "\e96a";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path30:before {
    content: "\e96b";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path31:before {
    content: "\e96c";
    margin-left: -1.56640625em;
    color: rgb(255, 255, 255);
  }
  .icon-Glyph-Icon-3 .path32:before {
    content: "\e96d";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path33:before {
    content: "\e96e";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path34:before {
    content: "\e96f";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path35:before {
    content: "\e970";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path36:before {
    content: "\e971";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path37:before {
    content: "\e972";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  .icon-Glyph-Icon-3 .path38:before {
    content: "\e973";
    margin-left: -1.56640625em;
    color: rgb(35, 31, 32);
  }
  