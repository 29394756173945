.btn-primary:not(:disabled):not(.disabled):active {
  background-color: darken(#2b2e40,5%);
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(90 94 135 / 50%);
}
.btn-primary {
  background-color: #2b2e40;
  border: none;
  border-radius: 25px;
  
  &:hover,  &:focus, &:target {
    background-color: darken(#2b2e40, 5%);
    // box-shadow: 0 0 0 0.2rem rgb(90 94 135 / 50%);
  }
  &:focus {
    box-shadow: none;
  }

  &:disabled {
    border-radius: 25px;
  }
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: darken($dark-blue-color,5%);
}
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(90 94 135 / 50%);
}
.btn-secondary {
  background-color: $dark-blue-color;
  border: none;
  color: $color-black;
  
  &:hover,  &:focus, &:target {
    background-color: darken($dark-blue-color,5%);
    box-shadow: 0 0 0 0.2rem rgb(90 94 135 / 50%);
  }
  &:focus {
    box-shadow: none;
  }
}
  
.btn-secondary {
  border: 2px solid $dark-blue-color;
  color: $color-black;
  background-color: white;
}
  
.btn-danger {
  border:none;
  background-color: $color-danger;
  &:hover {
    box-shadow: 0 0 0 0.2rem rgb(225 83 97 / 50%);

  }
}
.btn {
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
}
@include mobile {
  .btn {
    width: 100%;
    margin-bottom: 5px;
  }
}
@include tablet {
  .btn {
    width: 100%;
  }
}
@include desktop {
  .btn {
    width: 300px;

   &.full-width {
      width: 100%;
    }
  }
}

.btn-border-less {
  background-color: white;
  border: none;
}

.fa-plus-circle {
  font-size: 30px;
  color: $dark-blue-color;

  &:hover {
    color: darken($dark-blue-color,20%);;
  }
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgb(90 94 135 / 50%);
  border-color: $light-blue-color;
}
  

.btn-primary.disabled, .btn-primary:disabled {
  &:hover{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
