@import './mixins';
@import './variables';

@import './buttons';
@import './icons';
// @import '~@ionic/angular/css/core.css';
// @import '~@ionic/angular/css/normalize.css';
// @import '~@ionic/angular/css/structure.css';
// @import '~@ionic/angular/css/typography.css';

// @import '~@ionic/angular/css/padding.css';
// @import '~@ionic/angular/css/float-elements.css';
// @import '~@ionic/angular/css/text-alignment.css';
// @import '~@ionic/angular/css/text-transformation.css';
// @import '~@ionic/angular/css/flex-utils.css';

// @import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
/* roboto-regular - latin */
/* roboto-regular - latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   src: local(''),
//   url('./../assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//   url('./../assets/fonts/roboto-v29-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }

// @font-face {
//   font-family: 'icomoon';
//   src:  url('./../assets/fonts/icomoon.eot?v1bt22');
//   src:  url('./../assets/fonts/icomoon.eot?v1bt22#iefix') format('embedded-opentype'),
//     url('./../assets/fonts/icomoon.ttf?v1bt22') format('truetype'),
//     url('./../assets/fonts/icomoon.woff?v1bt22') format('woff'),
//     url('./../assets/fonts/icomoon.svg?v1bt22#icomoon') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

// [class^="icon-"], [class*=" icon-"] {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'icomoon' !important;
//   speak: never;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// .icon-auction:before {
//   content: "\e900";
// }

// .icon-info:before {
//   content: "\e901";
// }

@media (min-width: 992px) {
  .container-wider {
    max-width: 100%;
  }
} 

@media (min-width: 1200px) {
  .container-wider {
      max-width: 1300px;
  }
}


@font-face {
  font-family: 'BioRhyme';
  src: url('/assets/fonts/BioRhyme-VariableFont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.page-container {
  margin-top:120px;
  @include toTablet{
    margin-top: 40px;
  }
}

body {
  font-family: 'arial';
}

html {
  scroll-behavior: smooth;
}

a {
  color: #2b2e40;

  &:hover 
  {
    color: #2b2e40;
  }
}

h1 {
  font-size: 2.5rem;
  color: darken(#65837B, 20%);

  @include mobile {
    font-size: 1.8rem;
  }
}

h4 {
  font-size: 1.5rem;
  color: darken(#65837B, 20%);

  @include mobile {
    font-size: 1.2rem;
  }
}

h5 {
  color: darken(#65837B, 20%);
}

p{
  color: darken(#65837B, 20%);
}

.container-gray {
  background-color: #ebebeb;
}

table {
  color: darken(#65837B, 20%);
}

label {
  color: darken(#65837B, 20%);
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: -1px;
  vertical-align: 0.1em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.35em solid transparent;
  border-bottom: 0;
  border-left: 0.35em solid transparent;
}

.forms-modal {
  min-width: 650px;

  @include mobile {
    min-width: 96%;
  }
}

.registration-modal {
  min-width: 900px;

  @include toTablet {
    min-width: 96%;
  }
}

.carousel-indicators {
  display: none;
}

.carousel-control {
  display: none;
}
// BUTTON STYLES

.cursor-pointer {
  cursor: pointer;
}

.what {
  width: 100% !important;
}

.modal-content {
  padding: 20px;
  border-radius: 25px;
}